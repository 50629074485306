import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-modal.page.html',
  styleUrls: ['./download-modal.page.scss'],
})
export class DownloadModalPage implements OnInit {

  constructor(
    private modalContrller: ModalController
  ) { }

  ngOnInit() {
  }

  openStore(){
    document.getElementById('applink').click()
  }

  closeModal(){
    this.modalContrller.dismiss()
  }

}
