import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    // redirectTo: 'user-profile/feedback',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/login/login.module').then( m => m.LoginPageModule)
    // loadChildren: () => import('./pages/user-profile/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  
  {
    path: 'password',
    canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/password/password.module').then( m => m.PasswordPageModule)
  },
  // {
  //   path: 'about-app',
  //   loadChildren: () => import('./pages/about-app/about-app.module').then(m => m.AboutAppPageModule),

  // },
  {
    path: 'tearms-and-condition',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/tearms-and-condition/tearms-and-condition.module').then( m => m.TearmsAndConditionPageModule)
  },
  {
    path: 'enroll-user',
    canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/enroll-user/enroll-user.module').then( m => m.EnrollUserPageModule)
  },
  {
    path: 'enroll-success',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/enroll-success/enroll-success.module').then( m => m.EnrollSuccessPageModule)
  },
  {
    path: 'tabs',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'landing',
    canActivate:[AuthguardService],

    loadChildren: () => import('./pages/onbording/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'about-app',
    canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/about-app/about-app.module').then( m => m.AboutAppPageModule)
  },
  {
    path: 'play-game',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/games/play-game/play-game.module').then( m => m.PlayGamePageModule)
  },
  
 





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
