import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-py-errors',
  templateUrl: './py-errors.component.html',
  styleUrls: ['./py-errors.component.scss'],
})
export class PyErrorsComponent implements OnInit {
  @Input() errorMsg: any = '';
  constructor() { }

  ngOnInit() {}

}
