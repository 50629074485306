import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DownloadModalPage } from 'src/app/pages/dashboard/download-modal/download-modal.page';

@Component({
  selector: 'app-all-stats',
  templateUrl: './all-stats.component.html',
  styleUrls: ['./all-stats.component.scss'],
})
export class AllStatsComponent implements OnInit {
  total_tickets:any

  @Input() userStats: any
  constructor(
    private router :Router,
    private modalController: ModalController
  ) { }

  ngOnInit() {
     
  }

  budCoins(){
    this.router.navigateByUrl('/tabs/user-profile/point-ledger')
  }
  tickets(){
    this.router.navigateByUrl('/tabs/fifa-tickets/tickets-list')
  }

  weeklyTasks(){
    this.router.navigateByUrl('/tabs/fifa-tickets/weekly-draw')
  }

  checkin(){
    this.router.navigateByUrl('/tabs/find-bud')
  }

  rewardsRedeemed(){
    this.router.navigateByUrl('/tabs/user-profile/my-winnings')
  }


  
  async showDownloadModal() {
    const modal = await this.modalController.create({
      component: DownloadModalPage,
      cssClass: 'instructions-modal',
    });

    modal.onDidDismiss()
      .then((status) => {
        
      });

    return await modal.present();
  }
}
