import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfirmAlertService {
  alert: any;
  constructor(public alertController: AlertController) { }

  async presentAlert(data?, msg?, cssClass?, okBtn=1) {
    return new Promise(async (resolve) => {
      this.alert = await this.alertController.create({
        cssClass:  'v-scan-alert' ,
        header: (data) ? data : '',
        message: (msg) ? msg : '',
        buttons: [ (okBtn) ? {  
          text: 'Okay',
          cssClass: 'okBtnclass',
            handler: (ok) => {
              resolve(true);
            }
          } : ''
        ]
      });
      this.alert.present();
      
    });
  }
  dismiss() {
    try {
      this.alert.dismiss().catch(() => {});
    } catch (e) {} 
    
  }
}
