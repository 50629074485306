import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authguard/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  source_from: any
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private androidPermissions: AndroidPermissions,
    public alertController: AlertController,
    public auth: AuthenticationService,
    private acitivatedRoute: ActivatedRoute
  ) {

      this.initializeApp();
      
  }

  initializeApp() {    

    this.platform.ready().then(() => {
      this.checkRewardCode()
      this.statusBar.styleLightContent()
      this.splashScreen.hide();

      if (this.platform.is('android')) {
        this.source_from = 2;
      }
      else if (this.platform.is('ios')) {
        this.source_from = 3;
      }
      else {
        this.source_from = 4;
      }

      if (this.platform.is('mobile')) {
        // this.updateservice.descideUpdateAction(this.source_from);
      }
      this.authenticationService.authenticationState.subscribe(state => {
        let url = window.localStorage.getItem('pageUrl');
        if (state) {
          // (url)? this.router.navigate([url]):this.router.navigate(['/rootTabs']);
          this.router.navigate(['/tabs']); 
          // this.router.navigate(['/tabs']); 

        } else {

          this.router.navigate(['/landing']);
          // this.router.navigate(['/enroll-user']);

        }
      });
      // let status bar overlay webview
      // this.statusBar.overlaysWebView(true);
      // set status bar to white
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');

    });

  }

  checkRewardCode(){
    
    if(this.platform.getQueryParam('id')){
      let qrID = this.platform.getQueryParam('id')

      localStorage.setItem('qrId', qrID)
      console.log(qrID, 'qrid')
    }
    
  }



}
