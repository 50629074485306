/* tslint:disable */
import { Injectable } from '@angular/core';
import { VARS } from '../constants/constants.service';

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: 'root',
})
export class ApiConfiguration {
    //DEV
    //  baseUrlNew ='https://appapi.budspacev4.bigcityvoucher.co.in/v1/';
    // Prod
    baseUrlNew = 'https://staging29api.bigcityexperiences.co.in/v1/'

    // Google Maps Key
    googleMapsKey = 'AIzaSyCbCXvsU1WQyyJ2CoHkFWEbR45PI4NHBwk'; 
    googleMapLocation = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=';
    getLocationFromLatLng = this.baseUrlNew + 'user/location?';

    // budspace
    getInvoices = this.baseUrlNew + 'user/loadallinvoices';
    getInvoiceById = this.baseUrlNew + 'user/getuploadedinvoicesonhappyhours?transaction_id='
    getPointLedger = this.baseUrlNew + 'user/pointsledger'
    getTrackOrder = this.baseUrlNew + 'reward/orderhistory'
    getRewards = this.baseUrlNew + 'reward/productlisthome?program_id='+VARS.PROGRAM_ID;
    deleteProductFromCart = this.baseUrlNew + 'reward/deletecartbyprod'
    getProductDetails = this.baseUrlNew + 'reward/productdetails?program_id='+VARS.PROGRAM_ID+"&product_id=";
    viewCart = this.baseUrlNew + 'reward/viewcart?program_id='+VARS.PROGRAM_ID;
    addCart = this.baseUrlNew + 'reward/savecart';
    addAddress = this.baseUrlNew + 'reward/addshippingaddress';
    getShippingAddresses = this.baseUrlNew + 'reward/getshippingaddbyid?program_id='+VARS.PROGRAM_ID+"&shipping_id=";
    getStateList = this.baseUrlNew + 'user/getstatelist?program_id='+VARS.PROGRAM_ID;
    getCityL = this.baseUrlNew + 'user/getcitylist?program_id='+VARS.PROGRAM_ID+'&state_id=';
    proceedCheckout = this.baseUrlNew + 'reward/checkout?program_id='+VARS.PROGRAM_ID;
    ordersConfirm = this.baseUrlNew + 'reward/orderconfirm?program_id='+VARS.PROGRAM_ID;
    orderPlaced = this.baseUrlNew + 'reward/orders';
    chooseVoucher =this.baseUrlNew + 'reward/choose-voucher'
    getDashBordMenu = this.baseUrlNew +'user/dashboard-menu-list'
    getWeeklyDraws = this.baseUrlNew +'reward/getweeklydrawtransactions'
    getWeeklyWinnerList = this.baseUrlNew + 'reward/getweeklydrawwinners'
    getProductSizes = this.baseUrlNew + 'reward/productsizes?program_id='+VARS.PROGRAM_ID+'&reward_product_id='

    //OnBoarding
    loginUrl = this.baseUrlNew  + 'user/login';
    verifyotp = this.baseUrlNew + 'user/validateotp';
    resendOTP = this.baseUrlNew+'user/resendotp';
    signUp = this.baseUrlNew + 'user/customerregistration'
    getCities = this.baseUrlNew + 'user/getcitylist'
    signUpResendOtp = this.baseUrlNew + 'user/signup-otp'
    tearmsUpdate = this.baseUrlNew + 'user/acceptterms';
    getTerms = this.baseUrlNew + 'user/get-terms';
    userSignup = this.baseUrlNew + 'user/signup-otp';

    getAppInfo =  this.baseUrlNew + 'user/getsitesettings?campaign_id=1';

    //dashBoard
    getBanners = this.baseUrlNew + 'user/get-app-banners?page_name='
    getFifatickets = this.baseUrlNew + 'user/getfifatickets'
    getBuds = this.baseUrlNew + 'user/getbuds'
    getHappyHourOutlets = this.baseUrlNew + 'user/getstorelocatordetails';
    userStats = this.baseUrlNew + 'user/userstatistics'

  // games
    getGames = this.baseUrlNew + 'game/readgame'
    createGame = this.baseUrlNew + 'game/creategame?game_id='

    getMyWinnings = this.baseUrlNew + 'game/mywinnings'
    cliamMyWinning = this.baseUrlNew + 'game/claimreward'
    cliamPhysicalReward = this.baseUrlNew +'user/claimweeklydrawreward'


  // static content
  staticContent = this.baseUrlNew + 'adminreport/static-content?id='

// profile
edituser = this.baseUrlNew + 'user/profileupdate'
    getProfileDetails = this.baseUrlNew + 'user/user-profile';
    //getAllStates = this.baseUrlNew + 'user/states'
    getEditProfileInfo = this.baseUrlNew + "user/edit-profile?program_id="+VARS.PROGRAM_ID;
    getRedemptionData = this.baseUrlNew + 'ecommerce/orderhistory?page='
    getFaqData = this.baseUrlNew + "user/faq?program_id=1"
    getContacts = this.baseUrlNew + "user/get-contact"


    // QR scan
    getUserByUniqueCode = this.baseUrlNew + 'earnings/get-userby-uniquecode?unique_code='
    QrScanModule = this.baseUrlNew + 'earnings/earn'

    // points ledger
    getPointsLedger = this.baseUrlNew + 'earnings/ledger?page='

    // Transfer Points
    searchByPhoneNumber = this.baseUrlNew + 'earnings/search-user?search='
    transferPoints = this.baseUrlNew + 'earnings/transfer-points'
    recentTransfers = this.baseUrlNew + 'earnings/recent-transfers'

  //  Redeem Cash
    addBankDetails = this.baseUrlNew + 'earnings/add-bankdetails'
    redemcash = this.baseUrlNew + 'earnings/redeem-cash'
    cashRedemptionSummary  = this.baseUrlNew + 'earnings/cash-redemption-summary?page='

   
    // FOC
    getFocBrands = this.baseUrlNew + 'ecommerce/get-foc-brands'
    addToCart = this.baseUrlNew + 'ecommerce/savecart'
    getCartItems = this.baseUrlNew + 'ecommerce/viewcart'
    removeFromCart = this.baseUrlNew + 'ecommerce/deletecartbyprod'
    checkout = this.baseUrlNew + 'ecommerce/checkout'
    placeOrder = this.baseUrlNew + 'ecommerce/orders'
    getUnmateralizedPoints = this.baseUrlNew + 'ecommerce/unmaterialisedpoint'
    placeOrderAndMateralize = this.baseUrlNew + 'ecommerce/orderconfirm'
    offersDetails =  this.baseUrlNew + 'offer/readoffer?'

    // Notification
    getNotifications  = this.baseUrlNew + 'user/notifications'



    updateProfile = this.baseUrlNew + "user/update-profile";
    getDashboardData = this.baseUrlNew + "user/dashboard?program_id="+VARS.PROGRAM_ID;
    getPrivileges = this.baseUrlNew + "reward/getprivileges?program_id="+VARS.PROGRAM_ID;
    getPrivilegesDetail = this.baseUrlNew + "reward/getprivilegebyid?program_id="+VARS.PROGRAM_ID+"&privilege_id=";
    getOffers = this.baseUrlNew + "reward/getoffers?program_id="+VARS.PROGRAM_ID;
    privilegeBooking = this.baseUrlNew + "reward/privilegebooking";
    getCity = this.baseUrlNew + "reward/getcity?program_id="+VARS.PROGRAM_ID;
    getVenue = this.baseUrlNew + "reward/getvenue?program_id="+VARS.PROGRAM_ID+"&privilege_id=";
    claimOffer = this.baseUrlNew + "reward/claimoffer";
    getActivityRecord = this.baseUrlNew + "reward/getbookings?program_id="+VARS.PROGRAM_ID;
    getQuery = this.baseUrlNew + 'queries/get-query-types?program_id='+VARS.PROGRAM_ID
    getSubQuery = this.baseUrlNew + 'queries/get-query-sub-types?program_id='+VARS.PROGRAM_ID
    submitQuery = this.baseUrlNew + 'queries/send-enquiry'
    getProgramDetails = this.baseUrlNew + 'user/program-details?program_id=' + VARS.PROGRAM_ID
    getFaq = this.baseUrlNew + 'faqs/get-faqs?program_id=' + VARS.PROGRAM_ID
    getClaimedData = this.baseUrlNew + 'reward/rewarddetails?program_id=' + VARS.PROGRAM_ID
    getEngagement = this.baseUrlNew + 'engagement/get-engagement?program_id=' + VARS.PROGRAM_ID;
    bookEngagement = this.baseUrlNew + 'engagement/book-engagement';
    getCenturyHub = this.baseUrlNew + 'brand-hub/get-brand-hub?program_id=' + VARS.PROGRAM_ID + '&brand_hub_type=';
    getTerritory = this.baseUrlNew + 'user/get-regions?program_id='+ VARS.PROGRAM_ID;
    getState = this.baseUrlNew + 'user/get-state-byregion?program_id='+ VARS.PROGRAM_ID+'&region_id=';
    getCityList = this.baseUrlNew + 'user/get-city-bystate?program_id='+ VARS.PROGRAM_ID+'&state_id=0&branch_id=';
    getBranch = this.baseUrlNew + 'user/get-branch-bycity?program_id='+ VARS.PROGRAM_ID+'&state_id=';
    submitEnrollmentStep1 = this.baseUrlNew + 'user/enrollment-otp';
    submitEnrollmentOTPVerify = this.baseUrlNew + 'user/enrollment-otp-verification';
    getEnrollmentDetails = this.baseUrlNew + 'user/getaid-byid?program_id='+ VARS.PROGRAM_ID+'&user_id=';
    userEnroll = this.baseUrlNew + 'user/enroll-user';
    enrollStatus = this.baseUrlNew + 'user/enrollment-status?program_id='+ VARS.PROGRAM_ID;
    readNotification = this.baseUrlNew + 'notification/notificationread';
    getTestimonials = this.baseUrlNew + 'testimonials/get-testimonials?program_id='+ VARS.PROGRAM_ID;
    enrollJuniorUser = this.baseUrlNew + 'user/enroll-junior-architect';
    getAID = this.baseUrlNew + 'user/get-aid?program_id='+ VARS.PROGRAM_ID;
    getJAID = this.baseUrlNew + 'user/get-junior-aid?program_id='+ VARS.PROGRAM_ID+'&id=';
    deleteJAID = this.baseUrlNew + 'user/delete-aid?program_id='+ VARS.PROGRAM_ID;
    


    //govardhan
    codeUpload = this.baseUrlNew + "reward/upload-code";
    invoiceUpload = this.baseUrlNew + "user/happyhoursuploadinvoice";
    checkIn = this.baseUrlNew + "user/checkin";
}
export interface ApiConfigurationInterface {
    baseUrl?: string,
    baseUrlNew?:string,
    logInUrl?:string
}


















