import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  NavController
} from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Platform } from '@ionic/angular';
import { ToasterService } from '../toaster/toaster.service';
import { ToastController } from '@ionic/angular';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { VARS } from '../constants/constants.service';

@Injectable({
  providedIn: 'root'
})

export class HelperService {
  
  constructor(private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private transfer: FileTransfer,
    private androidPermissions: AndroidPermissions,
    private platform: Platform,
    private toastCtrl: ToastController,
    private file: File,
    private toast: ToasterService,
    private fileOpener: FileOpener
    ) { }

    pushRootPage(page, navData = null) {
      return new Promise(resolve => {
        navData ? this.navCtrl.navigateRoot(page, {state: navData}).then(() => {
          return resolve(true);
        }).catch((e) => {
          console.log('ePage', e);
          return resolve(false);
        }) : this.navCtrl.navigateRoot(page).then(() => {
          return resolve(true);
        }).catch((e) => {
          console.log('ePage', e);
          return resolve(false);
        });
      });
    }
    pushPage(page, navData = null) {
      console.log('pp',page,navData)
      return new Promise(resolve => {
        navData ? this.navCtrl.navigateForward(page, {state: navData}).then(() => {
          return resolve(true);
        }).catch((e) => {
          console.log('ePage1', e);
          return resolve(false);
        }) : this.navCtrl.navigateForward(page).then(() => {
          return resolve(true);
        }).catch((e) => {
          console.log('ePage2', e);
          return resolve(false);
        });
      });
    }
    popPage() {
      return new Promise(resolve => {
        this.navCtrl.pop().then(() => {
          return resolve(true);
        }).catch((e) => {
          return resolve(false);
        });
      });
    }
    navParams() {
      return new Promise(resolve => {
        this.activatedRoute.queryParams.subscribe(async () => {
        try {
          const routParams = this.router.getCurrentNavigation().extras.state || null;
          return resolve(routParams);
        } catch (e) {
          return resolve(undefined);
        }
      });
    });
  }

  // download file
  downloadFilePerm(url, myFileName = null) {
    this.platform.ready().then(async () => {
      if (this.platform.is('android')) {
        this.androidPermissions.hasPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(status => {
          if (status.hasPermission) {
            this.androidPermissions.hasPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(async status2 => {
              if (status2.hasPermission) {
                await this.downloadFile(url, myFileName);
              } else {
                this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(async status3 => {
                  if (status3.hasPermission) {
                    await this.downloadFile(url, myFileName);
                  }
                });
              }
            });
          } else {
            this.androidPermissions.requestPermissions(
                [
                  this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
                  this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
                ]
            ).then(async status4 => {
              if (status4.hasPermission) {
                await this.downloadFile(url, myFileName);
              }
            });
          }
        });
      } else {
        await this.downloadFile(url, myFileName);
      }
    }).catch(() => {});
  } 

  downloadFile(url, myFileName = null) {
    const fileTransfer: FileTransferObject = this.transfer.create();
      // let url = 'http://www.example.com/file.pdf';
      
      // dataDirectory
      fileTransfer.download(url, this.file.externalRootDirectory + 'Download/myQr.png').then(async (entry) => {
          console.log('download complete: ' + entry.toURL());
          await this.fileOpenToast(entry.toURL(), myFileName);
      }, (error) => {
          // handle error
          console.log('error', error)
      });
  }

  openFile(filePath) {
    this.file.resolveLocalFilesystemUrl(filePath).then((entry: FileEntry) => {
      entry.file (success => {
        const mimeType = success.type;
        this.fileOpener.showOpenWithDialog(filePath, mimeType).catch(async e => { // todo change "showOpenWithDialog" to "open" if error
          if (e.status === 9) {
            await this.toast.presentToast('No app found to open this file type.');
          } else {
            await this.toast.presentToast('Error opening file.');
          }
        });
      }, error => {
        // no mime type found;
      });
    }).catch(() => {});
  }
  async fileOpenToast(url, fileName = 'File') {
    // this.helper.dismissLoading();
    const toast = await this.toastCtrl.create({
      header: '', // fileName,
      message: 'File has been downloaded', // '' + VARS.SUCCESS_FILE_BEEN_DOWNLOAD,
      position: 'bottom',
      cssClass: 'openFileToast',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'btnCancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }, {
          text: 'Open',
          cssClass: 'btnOpen',
          handler: () => {
            this.openFile(url);
          }
        }
      ]
    });
    setTimeout(() => {
      // this.helper.dismissLoading();
      toast.present();
    }, 200);
  }


  loadMoreRecords(event, offset, recordsFiltered, limit: any = VARS.LIMIT_LIST, pages = true) {
    console.log('loadmore',event, offset, recordsFiltered, limit, VARS.LIMIT_LIST, pages)
    return new Promise(resolve => {
      setTimeout(() => {
        if (pages) {
          if ((((offset - 1) * limit) + limit) < recordsFiltered) {
            offset++;
            return resolve({page: offset, limit, reload: true});
          } else {
            event.target.disabled = true;
            // event.complete();
            return resolve({page: offset, limit, reload: false});
          }
        } else {
          const currentCount = limit + offset;
          if (currentCount < recordsFiltered) {
            if ((recordsFiltered - currentCount) > limit) {
              offset += limit;
            } else {
              offset = currentCount;
              limit = (recordsFiltered - currentCount);
            }
            return resolve({offset, limit, reload: true});
          } else {
            event.target.disabled = true;
            return resolve({offset, limit, reload: false});
          }
        }
      }, 500);
    });
  }

}
