import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { VARS } from 'src/app/services/constants/constants.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import _ from 'lodash';

@Component({
  selector: 'app-outlet-list',
  templateUrl: './outlet-list.component.html',
  styleUrls: ['./outlet-list.component.scss'],
})
export class OutletListComponent implements OnInit {
  outletLists
  pageNo = VARS.PAGE; rowSize = VARS.LIMIT_LIST;
  totalRc = 0;
  isLoading = true; isProgress = true;
  constructor(
    private modalController: ModalController,
    private apiService : ApiService,
    private analytics: AnalyticsProvider,
    private apiConfig : ApiConfiguration,
    private helper: HelperService
  ) { 
    this.outletList()
  }

  ngOnInit() {}

  closeModal(){
    this.modalController.dismiss()
  }
  /* outletList(){
    this.apiService.get(this.apiConfig.getHappyHourOutlets +`?latitude=19.11558890&longitude=72.85677860&range=15`)
    .subscribe((res:any)=>{
      this.outletLists =res.data
    })
  } */

  outletList(ev=null) {
    console.log('evvvv', ev, this.pageNo);
    this.isProgress = true;
    if (!ev) {
      this.pageNo = VARS.PAGE;
      this.outletLists = [];
      this.isLoading = true;
    }
    this.apiService.getHeaders(this.apiConfig.getHappyHourOutlets +"?latitude=&longitude=&range=15&page="+ this.pageNo).subscribe(async (res: any) => {
      console.log('rreeesss', res);
      // const d = res.data;
      // this.outletLists = res.data;
       if (res.body.data) {
        if (!ev) {
          this.outletLists = res.body.data?.outletlist;
        } else {
          res.body.data?.outletlist.map(item => {
            this.outletLists.push(item);
          }); 
        }
      }
      const Total = res.headers.get("X-Pagination-Total-Count");
      this.totalRc = (res && res.headers && Total) ? _.toNumber(Total) : 0;
      console.log('total', this.totalRc)
      if (ev) {
        try {
          ev.target.complete();
        } catch (e) {}
      }  
      this.isLoading = this.isProgress = false;
    },err=>{
      
    })
  }

  loadMore(event) {
    console.log('wwww111', event);
    this.helper.loadMoreRecords(event, this.pageNo, this.totalRc).then((res: any) => {
      console.log('resloadmore', res);
      if (res && res.reload) {
        this.pageNo = res.page;
        this.outletList(event);
      }
    }).catch((e) => {
      console.log('err', e)
    });
  } 

}
