import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AnalyticsProvider } from 'providers/analytics/analytics';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpConfigInterceptor } from './services/interceptors/http-loading-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Camera } from '@ionic-native/camera/ngx';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { ReactiveFormsModule } from '@angular/forms';
import { AuthguardService } from './services/authguard/authguard.service';
import { IonicStorageModule } from '@ionic/storage';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { AuthenticationService } from './services/authguard/authentication.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ChartsModule } from 'ng2-charts';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from '@ionic-native/market/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { FCM } from '@ionic-native/fcm/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { MaterialModule } from './material/material.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
// import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    PipesModule,
    MaterialModule,
    ComponentsModule,
    IonicStorageModule.forRoot(),
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicSelectableModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthguardService,
    AuthenticationService,
    FirebaseAnalytics,
    Geolocation,
    ScreenOrientation,
    SocialSharing,
    Camera,
    BarcodeScanner,
    YoutubeVideoPlayer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    StreamingMedia,
    Keyboard,
    AndroidPermissions,
    AppVersion,
    InAppBrowser,
    AnalyticsProvider,
    Market,
    // FCM,
    // Clipboard,
    SMS,
    File,
    FileTransfer,
    FileOpener,
    ZXingScannerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
