import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PyErrorsComponent } from './py-errors/py-errors.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import {PipesModule} from '../pipes/pipes.module';
import { MainStatsComponent } from './main-stats/main-stats.component';
import { AllStatsComponent } from './all-stats/all-stats.component';
import { OutletListComponent } from './outlet-list/outlet-list.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    ReactiveFormsModule, FormsModule,
    PipesModule
  ],
  declarations: [
    PyErrorsComponent,
    VideoPlayerComponent,
    MainStatsComponent,
    AllStatsComponent,
    OutletListComponent
  ],
  exports: [
    PyErrorsComponent,
    VideoPlayerComponent,
    MainStatsComponent,
    AllStatsComponent,
    OutletListComponent
  ]
})
export class ComponentsModule {
}
