export enum $siteUrls {
  apiUrlPath = '',
}

export enum VARS {
  APP_TIMEZONE = 'Asia/Kolkata',
  APP_TIMEZONE_NUM = '+0530',
  TZ_TIMEZONE_FORMAT = 'ha z',
  DEFAULT_DATE_FORMAT = 'MM/dd/yyyy',
  APP_TIMEZONE_LOCALES = 'en-US',
  MSG_BACK_BUTTON = 'Tap again to exit',
  EMAIL_PATTERN = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  URL_PATTERN = '/((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\\w]+@)[A-Za-z0-9.-]+)((?:\\/[\\+~%\\/.\\w-_]*)?\\??(?:[-\\+=&;%@.\\w_]*)#?(?:[\\w]*))?)/',
  OFFSET = 0,
  PAGE = 1,
  LIMIT_LIST = 20,
  SORT_BY = 'DESC',
  SORT_BY_ASC = 'ASC',
  MAX_FILE_SIZE_MB = 5,
  ON_HTTP_CONNECTION_LOST = 'The Internet connection appears to be offline, please try again later',
  ON_CONNECT_NETWORK = 'You are online',
  ON_DISCONNECT_NETWORK = 'You are offline. Some functionality may be unavailable',
  LOGGED_OUT = 'Logged out.',
  ERROR_FILE_DOWNLOAD = 'File download failed. Please try again.',
  ERROR_NO_FILE_APP = 'No app found to open this file type.',
  ERROR_OPENING_FILE = 'Error opening file.',
  SUCCESS_FILE_DOWNLOAD = 'File has been downloaded.',
  SUCCESS_FILE_BEEN_DOWNLOAD = ' has been downloaded.',
  ALLOWED_FILE_TYPE = 'image/jpeg,image/jpg,image/png,image/gif',
  OTHER_FILE_TYPE = 'image/jpeg,image/jpg,image/png,application/pdf',
  IMG_PDF_FILE_TYPE = 'application/pdf',
  IMG_PDF_FILE_TYPE_EXT = 'pdf',

  INVALID_USER = 'Logged out due to invalid Login.',
  ERROR_CONNECTION = 'Connection error. Please try again later',
  MULTIPLE_SELECT_IMG = 5,
  DEFAULT_FILE_TYPE = 'image/jpeg,image/jpg,image/png,image/gif',
  MAX_FILE_SIZE_5 = 5242880, // 5000000,
  MAX_FILE_SIZE_10 = 10485760, // 10000000,
  AMOUNT_PATTERN = '^[\\-\\+]?\\d+$',
  NUMERIC_PATTERN = '[0-9]*',
  NUMERIC_PATTERN2 = '^[0-9]*$',
  PATTERN2 = '^[0-9]{1,4}(?:\\.[0-9]{1,2})?$',
  PATTERN1 = '^[0-9]{1,6}(?:\\.[0-9]{1,2})?$',
  PATTERN_COMMAS = '[0-9]{5}(,[0-9]{5})*',
  MIN_PASS_LENGTH = 6,
  MIN_JOURNEY_TEXT_LENGTH = 200,
  PINCODE_CODE_LENGTH = 6,
  PHONE_LENGTH = 10,
  OTP_LENGTH = 6,
  MIN_ACCOUNT_NUMBER = 6,
  MAX_ACCOUNT_NUMBER = 17,
  ABA_NUMBER = 9,
  MIN_AMT= 1,
  MIN_PHONE_LENGTH = 10,
  MAX_PHONE_LENGTH = 10,
  PROGRAM_ID = 1,
}
export enum FILE_VAR {
  MULTIPLE_SELECT_DEFAULT = 5,
  MULTIPLE_SELECT = 5,
  MAX_SIZE_MB = 2,
  MAX_FILE_SIZE_DEFAULT = 2097,
  CoverPhotoFileSize = 1048576,
  JourneyPhotoFileSize = 10485760,
  MAX_FILE_SIZE_5 = 5242880,
  MAX_FILE_SIZE_10 = 10485760,
  MAX_FILE_SIZE_20 = 20971520,
  ERROR_FILE_DOWNLOAD = 'File download failed. Please try again.',
  ERROR_NO_FILE_APP = 'No app found to open this file type.',
  ERROR_OPENING_FILE = 'Error opening file.',
  SUCCESS_FILE_DOWNLOAD = 'File has been downloaded.',
  SUCCESS_FILE_BEEN_DOWNLOAD = ' has been downloaded.',
  ALLOWED_FILE_TYPE = 'image/jpeg,image/jpg,image/png,image/pjepg', // image/gif,image/tiff,
  IMG_FILE_TYPE = 'image/jpeg,image/jpg,image/png,image/gif',
  CoverPhotoFileType = 'image/jpeg,image/jpg,image/png,image/gif',
  JourneyPhotoFileType = 'image/jpeg,image/jpg,image/png,image/gif',
  OTHER_FILE_TYPE = 'image/jpeg,image/jpg,image/png,application/pdf',
  IMG_PDF_FILE_TYPE = 'application/pdf',
  IMG_PDF_FILE_TYPE_EXT = 'pdf',
  SUB_HEADER1 = '**Allowed - jpeg, jpg, png, gif, pjepg, tiff, pdf, msword.',
  SUB_HEADER2 = '**Allowed - jpeg, jpg, png, gif. MaxSize - 10 MB. Max 10 file(Choose from gallery).',
  SUB_HEADER3 = '**Allowed - csv. (The contacts with same email address and phone number shall be skipped)',
  SUB_HEADER = '**Allowed - jpg ,jpeg, png, gif. MaxSize - 2 MB.',
  DEFAULT_FILE_TYPE           = 'image/jpg,image/jpeg,image/png,image/gif',
  CONTACTS_FILE_TYPE          = 'text/csv,text/plain,application/csv,text/comma-separated-values',

}
export enum VALIDATION_MSG {
  ERR_REQUIRED_FIELD = '*Please provide this information.',
  ERR_REQUIRED_SELECT = '*Please select the value.',
  ERR_EMAIL_PATTERN = '*Email format is not correct',
  ERR_URL_PATTERN = '*URL format is not correct',
  ERR_FIELD_NOT_MATCH = '*Fields do not match',
  ERR_NUMERIC_ONLY = '*Only Numeric Values Allowed',
  ERR_GREATER_OR_EQUAL = '*Value should be greater than or Equal to ',
  ERR_LESS_OR_EQUAL = '*Value should be Less than or Equal to ',
  ERR_PASS_MIN_LENGTH = '*Password must be minimum 6 characters long!',
  ERR_GREATER_THAN_ZERO = '*Value should be greater than or Equal to 1',
  CUSTOMER_ERR_LENGTH_NOT_MATCH = '*Length do not match.',
  ERR_PERMISSION_ALLOW = 'Please allow camera permissions.',
  ERR_INVALID_USER = 'Logged out due to invalid Login.',
  ERR_NOT_AUTHORISED_USER = 'You are not authorised person. Please contact admin.',
  ERR_FILE_TYPE = 'File type not allowed!',
  ERR_FILE_SIZE = 'File size not allowed!',
  ERR_FILE_SIZE_AND_TYPE = 'One or more file(s) SIZE or TYPE not allowed!',
  ERR_LOGOUT = 'Logged out.',

  ERR_LENGTH_NOT_MATCH = '*Enter 10 digits only.',

  ERR_GREATER_THAN_ZERO_SHORT = '*Value should be >= 1',
  ERR_ZIPCODE_LENGTH = '*Enter 5 digits only.',
  ERR_DIGIT_ONLY = '*Enter number digits only.',
  ERR_FILE_SIZE_AND_TYPE_ONE = 'File size or type not allowed!',
  ERR_ITEM_LENGTH = 'Characters Should be between 1 and 999',
  ERR_CATEGORY_LENGTH = '*Max 100 Characters allowed',
  ERR_REQUIRED_SELECT_FILE = '*You must select at least one file.',
  ERR_CHARS_ONLY = '*Enter alphabets only.',
}

export declare type INPUT_TYPE_NAME = keyof typeof INPUT_TYPE_NAME_MAP;
declare const INPUT_TYPE_NAME_MAP: {
  _EMAIL,
  _URL,
  _PASSWORD,
  _INPUT,
  _SELECT,
  _EQUAL,
  _MINLENGTH_MAXLENGTH_SAME,
  _PATTERN_NUM_MIN_MAX,
  _PATTERN_NUM_MIN_MAX_LENGTH,
  _MAX_CHAR,
  _MIN_CHAR,
  _PATTERN,
  PASSWORD,
  PATTERN_NUM_MIN_MAX_LENGTH,
  EMAIL,
  EQUAL
  URL,
  MINLENGTH_MAXLENGTH_SAME,
  OTHER;
};